<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <!--Policy-->
      <v-card flat class="mb-3">
        <v-card-title v-if="!LINE.INFORMAL">Policy</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" lg="6">
              <text-field v-model="policyNumber" data-lpignore="true" :prepend-inner-icon="mdiPound"
                label="Policy Number" data-testid="details-policy-number" :success="Boolean(policyNumber)"
                :disabled="user.isGroupOne" @update:model-value="saveAttribute('policyNumber')">
                <template #append-inner>
                  <active-save-indicator :controller="savingBuffer.policyNumber.controller.value" />
                </template>
              </text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <autocomplete-field v-model="status" :prepend-inner-icon="mdiListStatus" label="Status"
                data-testid="details-status" :disabled="user.isGroupOne" :items="STATUS_OPTIONS"
                :success="Boolean(status)" @update:model-value="saveAttribute('status')">
                <template #append-inner>
                  <active-save-indicator :controller="savingBuffer.status.controller.value" />
                </template>
              </autocomplete-field>
            </v-col>
            <v-col cols="12" lg="6">
              <autocomplete-field v-model="state" label="State" :prepend-inner-icon="mdiHomeGroup"
                data-testid="details-state" :disabled="user.isGroupOne" :items="STATES" :success="Boolean(state)"
                @update:model-value="saveAttribute('state')">
                <template #append-inner>
                  <active-save-indicator :controller="savingBuffer.state.controller.value" />
                </template>
              </autocomplete-field>
            </v-col>
            <v-col cols="12" lg="6">
              <autocomplete-field v-model="product" label="Product" item-title="name" return-object
                :prepend-inner-icon="mdiSitemap" data-testid="details-product" :disabled="user.isGroupOne"
                :items="products" :success="Boolean(product)" @update:model-value="saveAttribute('product')">
                <template #append-inner>
                  <active-save-indicator :controller="savingBuffer.product.controller.value" />
                </template>
              </autocomplete-field>
            </v-col>
            <v-col v-if="LINE.LIFE || LINE.INFORMAL" cols="12" lg="6">
              <currency-input v-model="faceAmount" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                label="Death Benefit" data-testid="details-face-amount" :success="Boolean(faceAmount)"
                :disabled="user.isGroupOne" @update:model-value="saveAttribute('faceAmount')">
                <template #append-inner>
                  <active-save-indicator :controller="savingBuffer.faceAmount.controller.value" />
                </template>
              </currency-input>
            </v-col>
            <v-col v-if="product.ltcRider" cols="12" lg="6">
              <div class="checkbox-width">
                <checkbox-field v-model="ltcRider" :success="Boolean(ltcRider)" data-testid="details-ltc-rider"
                  :disabled="user.isGroupOne" @update:model-value="saveAttribute('ltcRider')">
                  <template #label>
                    LTC Rider
                    <active-save-indicator :controller="savingBuffer.ltcRider.controller.value" />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
            <v-col v-if="product.chronicIllnessRider" cols="12" lg="6">
              <div class="checkbox-width">
                <checkbox-field v-model="chronicIllnessRider" :success="Boolean(chronicIllnessRider)"
                  data-testid="details-chronic-illness-rider" :disabled="user.isGroupOne"
                  @update:model-value="saveAttribute('chronicIllnessRider')">
                  <template #label>
                    Chronic Illness Rider
                    <active-save-indicator :controller="savingBuffer.chronicIllnessRider.controller.value
                      " />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
            <v-col v-if="LINE.LIFE || LINE.DISABILITY" cols="12" lg="6">
              <div class="checkbox-width">
                <checkbox-field v-model="tia" :success="Boolean(tia)" data-testid="details-tia"
                  :disabled="user.isGroupOne" @update:model-value="saveAttribute('tia')">
                  <template #label>
                    Temporary Insurance
                    <active-save-indicator :controller="savingBuffer.tia.controller.value" />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="checkbox-width">
                <checkbox-field v-model="saveAge" :prepend-inner-icon="mdiCalendar" data-testid="save-age"
                  :disabled="user.isGroupOne" :success="Boolean(saveAge)"
                  @update:model-value="saveAttribute('saveAge')">
                  <template #label>
                    Save Age
                    <active-save-indicator :controller="savingBuffer.saveAge.controller.value" />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="checkbox-width">
                <checkbox-field v-model="messageClient" hide-details data-testid="message-client"
                  :success="Boolean(messageClient)" @update:model-value="saveAttribute('messageClient')">
                  <template #label>
                    Message Client Status Updates
                    <active-save-indicator
                      :controller="savingBuffer.messageClient.controller.value"
                    />
                  </template>
                </checkbox-field>
              </div>
              <div>
                Automatically send email and text message notifications when
                your case status updates to Submitted, Approved, Active, or when
                Conversion offers become available.
              </div>
            </v-col>

            <v-col v-if="isTargetPremiumProduct" cols="12" lg="6">
              <div class="checkbox-width">
                <checkbox-field v-model="conversion" data-testid="conversion" :disabled="user.isGroupOne"
                  :success="Boolean(conversion)" @update:model-value="saveAttribute('conversion')">
                  <template #label>
                    Is a Converted Case
                    <active-save-indicator :controller="savingBuffer.conversion.controller.value" />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template v-if="!LINE.INFORMAL">
        <!-- Applied Premium -->
        <v-card flat class="mb-3">
          <v-card-title>Applied</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <autocomplete-field v-model="appliedMode" label="Applied Mode" :prepend-inner-icon="mdiViewWeek"
                  data-testid="details-applied-mode" :items="modeOptions" :disabled="user.isGroupOne"
                  :success="Boolean(appliedMode)" @update:model-value="saveAttribute('appliedMode')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.appliedMode.controller.value" />
                  </template>
                </autocomplete-field>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input v-model="appliedPremium" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                  label="Applied Premium" data-testid="details-applied-premium" :disabled="user.isGroupOne"
                  :success="Boolean(appliedPremium)" @update:model-value="saveAttribute('appliedPremium')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.appliedPremium.controller.value" />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <autocomplete-field v-model="insuredRole.ratingApplied" :prepend-inner-icon="mdiStar"
                  label="Applied Rating" data-testid="details-applied-rating" :disabled="user.isGroupOne"
                  :items="RATINGS_BY_LINE[line]" :success="Boolean(insuredRole.ratingApplied)"
                  @update:model-value="saveAttribute('ratingApplied')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.ratingApplied.controller.value" />
                  </template>
                </autocomplete-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--Approved Premium-->
        <v-card flat class="mb-3">
          <v-card-title>Approved</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <autocomplete-field v-model="mode" label="Approved Mode" :prepend-inner-icon="mdiViewWeek"
                  data-testid="approved-mode" :disabled="user.isGroupOne" :success="Boolean(mode)" :items="modeOptions"
                  @update:model-value="saveAttribute('mode')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.mode.controller.value" />
                  </template>
                </autocomplete-field>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input v-model="premium" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                  label="Approved Premium" data-testid="approved-premium" :disabled="user.isGroupOne"
                  :success="Boolean(premium)" @update:model-value="saveAttribute('premium')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.premium.controller.value" />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <autocomplete-field v-model="insuredRole.ratingApproved" data-testid="details-approved-rating" clearable
                  label="Approved Rating" :prepend-inner-icon="mdiStar" :disabled="user.isGroupOne"
                  :items="RATINGS_BY_LINE[line]" :success="Boolean(insuredRole.ratingApproved)"
                  @update:model-value="saveAttribute('ratingApproved')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.ratingApproved.controller.value" />
                  </template>
                </autocomplete-field>
              </v-col>
              <v-col v-if="isTargetPremiumProduct" cols="12" lg="6">
                <currency-input v-model="targetPremium" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                  label="Target Premium" data-testid="details-target-premium" :disabled="user.isGroupOne"
                  :success="Boolean(targetPremium)" @update:model-value="saveAttribute('targetPremium')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.targetPremium.controller.value" />
                  </template>
                </currency-input>
              </v-col>
              <v-col v-if="(LINE.LIFE && isTargetPremiumProduct) || LINE.ANNUITY" cols="12" lg="6">
                <currency-input v-model="exchangeAmount" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                  data-testid="details-exchange-amount" label="Exchange Amount" :disabled="user.isGroupOne"
                  :success="Boolean(exchangeAmount)" @update:model-value="saveAttribute('exchangeAmount')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.exchangeAmount.controller.value" />
                  </template>
                </currency-input>
              </v-col>
              <v-col v-if="(LINE.LIFE && isTargetPremiumProduct) || LINE.ANNUITY" cols="12" lg="6">
                <currency-input v-model="lumpSum" include-decimals :prepend-inner-icon="mdiCurrencyUsd" label="Lump Sum"
                  data-testid="lump-sum" :disabled="user.isGroupOne" :success="Boolean(lumpSum)"
                  @update:model-value="saveAttribute('lumpSum')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.lumpSum.controller.value" />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input v-model="cashReceived" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                  label="Cash Received" data-testid="cash-received" :disabled="user.isGroupOne"
                  :success="Boolean(cashReceived)" @update:model-value="saveAttribute('cashReceived')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.cashReceived.controller.value" />
                  </template>
                </currency-input>
              </v-col>
              <v-col v-if="LINE.ANNUITY" cols="12" lg="6">
                <autocomplete-field v-model="taxType" :prepend-inner-icon="mdiBank" label="Tax Type"
                  data-testid="details-tax-type" :disabled="user.isGroupOne" :items="TAX_OPTIONS"
                  :success="Boolean(taxType)" @update:model-value="saveAttribute('taxType')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.taxType.controller.value" />
                  </template>
                </autocomplete-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat class="mb-3">
          <v-card-title>Underwriting Rationale</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col v-if="LINE.DISABILITY" cols="12">
                <autocomplete-field v-model="insuredRole.occupationClass" label="Occupation Class"
                  :prepend-inner-icon="mdiBriefcase" data-testid="occupation-class" :items="OCCUPATION_CLASSES"
                  :success="Boolean(insuredRole.occupationClass)"
                  @update:model-value="saveAttribute('occupationClass')">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.occupationClass.controller.value
                      " />
                  </template>
                </autocomplete-field>
              </v-col>
              <v-col cols="12">
                <underwriting-rationale v-model="insuredRole.underwritingRationale" :disabled="user.isGroupOne" :func="() => caseView.saveAttributes(['underwritingRationale'])
                  " />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- Policy Delivery -->
        <v-card flat class="mb-3">
          <v-card-title>Policy Delivery</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <div class="checkbox-width">
                  <checkbox-field v-model="carrierEDelivery" data-testid="carrier-e-delivery"
                    :disabled="user.isGroupOne" :success="Boolean(carrierEDelivery)"
                    @update:model-value="saveAttribute('carrierEDelivery')">
                    <template #label>
                      eDelivery
                      <active-save-indicator :controller="savingBuffer.carrierEDelivery.controller.value
                        " />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <template v-if="!carrierEDelivery">
                <v-col cols="12">
                  <autocomplete-field v-model="address" data-testid="shipping-address" label="Shipping Address"
                    :prepend-inner-icon="mdiSignRealEstate" :disabled="user.isGroupOne" :items="addresses"
                    :success="Boolean(address)" @update:model-value="saveAttribute('address')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.address.controller.value" />
                    </template>
                  </autocomplete-field>
                </v-col>
                <v-col cols="12">
                  <autocomplete-field v-model="trackingNumberType" item-value="text" label="Tracking Number Type"
                    :prepend-inner-icon="mdiTruckDelivery" data-testid="tracking-number-type"
                    :disabled="user.isGroupOne" :items="trackingTypes" :success="!!trackingNumberType"
                    @update:model-value="saveAttribute('trackingNumberType')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.trackingNumberType.controller.value
                        " />
                    </template>
                  </autocomplete-field>
                </v-col>
                <v-col cols="12">
                  <text-field v-model="trackingNumber" data-lpignore="true" label="Tracking Number"
                    :prepend-inner-icon="mdiBarcode" class="prepend-slot rounded-e has-prepend-text"
                    data-testid="tracking-number" :disabled="user.isGroupOne" :success="!!trackingNumber"
                    @update:model-value="saveAttribute('trackingNumber')">
                    <template #prepend>
                      <span class="prepend-text rounded-s ml-2 mr-n4" style="min-width: 450px">
                        {{ trackingNumberPrefix }}
                      </span>
                    </template>
                    <template #append-inner>
                      <app-button v-if="Boolean(trackingNumber)" :icon="mdiOpenInNew" :href="trackingNumberLink"
                        target="_blank" />
                      <active-save-indicator :controller="savingBuffer.trackingNumber.controller.value
                        " />
                    </template>
                  </text-field>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
        <!--Dates-->
        <v-card flat class="mb-3">
          <v-card-title>Dates</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <date-input v-model="appSignDate" label="App Sign Date" data-testid="app-sign-date" clearable
                  :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne" :success="Boolean(appSignDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.appSignDate.controller.value" />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="submittedDate" label="Submitted Date" data-testid="submitted-date" clearable
                  :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne" :success="Boolean(submittedDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.submittedDate.controller.value" />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="approvedDate" data-testid="approved-date" label="Approved Date" clearable
                  :prepend-inner-icon="mdiCalendar" :success="Boolean(approvedDate)" :disabled="user.isGroupOne">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.approvedDate.controller.value" />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="issueDate" label="Issue Date" data-testid="issue-date" clearable
                  :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne" :success="Boolean(issueDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.issueDate.controller.value" />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="deliveryRequirementsDueDate" data-testid="delivery-requirements-date"
                  label="Delivery Requirements Due Date" clearable :prepend-inner-icon="mdiCalendar"
                  :disabled="user.isGroupOne" :success="Boolean(deliveryRequirementsDueDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.deliveryRequirementsDueDate.controller
                        .value
                      " />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="sentToAgentDate" label="Sent to Agent Date" data-testid="sent-to-agent-date"
                  clearable :prepend-inner-icon="mdiCalendar" :success="Boolean(sentToAgentDate)"
                  :disabled="user.isGroupOne">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.sentToAgentDate.controller.value
                      " />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="policyDate" label="Policy Date" data-testid="policy-date" clearable
                  :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne" :success="Boolean(policyDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.policyDate.controller.value" />
                  </template>
                </date-input>
              </v-col>

              <v-col v-if="isTermProduct" cols="12" lg="6">
                <date-input v-model="conversionExpirationDate" label="Conversion Expiry" data-testid="conversion-expiry"
                  clearable :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne"
                  :success="Boolean(conversionExpirationDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.conversionExpirationDate.controller.value
                      " />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="inforceDate" label="Inforce Date" data-testid="inforce-date" clearable
                  :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne" :success="Boolean(inforceDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.inforceDate.controller.value" />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input v-model="closedDate" label="Closed Date" data-testid="closed-date" clearable
                  :prepend-inner-icon="mdiCalendar" :disabled="user.isGroupOne" :success="Boolean(closedDate)">
                  <template #append-inner>
                    <active-save-indicator :controller="savingBuffer.closedDate.controller.value" />
                  </template>
                </date-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--Benefits-->
        <template v-if="LINE.LTC || LINE.DISABILITY || LINE.LINKED_BENEFIT">
          <v-card flat class="mb-3">
            <v-card-title>Benefits</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="6">
                  <autocomplete-field v-model="eliminationPeriod" :prepend-inner-icon="mdiCalendar"
                    data-testid="elimination-period" label="Elimination Period" :disabled="user.isGroupOne"
                    :items="ELIMINATION_PERIODS" :success="Boolean(eliminationPeriod)"
                    @update:model-value="saveAttribute('eliminationPeriod')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.eliminationPeriod.controller.value
                        " />
                    </template>
                  </autocomplete-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <autocomplete-field v-model="benefitPeriod" :prepend-inner-icon="mdiCalendar" label="Benefit Period"
                    data-testid="benefit-period" :disabled="user.isGroupOne" :items="BENEFIT_PERIOD_OPTIONS"
                    :success="Boolean(benefitPeriod)" @update:model-value="saveAttribute('benefitPeriod')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.benefitPeriod.controller.value
                        " />
                    </template>
                  </autocomplete-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <currency-input v-model="benefitAmount" include-decimals :prepend-inner-icon="mdiCurrencyUsd"
                    label="Benefit Amount" data-testid="benefit-amount" :disabled="user.isGroupOne"
                    :success="Boolean(benefitAmount)" @update:model-value="saveAttribute('benefitAmount')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.benefitAmount.controller.value
                        " />
                    </template>
                  </currency-input>
                </v-col>
                <v-col v-if="LINE.LTC" cols="12" lg="6">
                  <autocomplete-field v-model="benefitMode" label="Benefit Mode" :prepend-inner-icon="mdiViewWeek"
                    data-testid="benefit-mode" :disabled="user.isGroupOne" :items="['Daily', 'Monthly']"
                    :success="Boolean(benefitMode)" @update:model-value="saveAttribute('benefitMode')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.benefitMode.controller.value" />
                    </template>
                  </autocomplete-field>
                </v-col>
                <v-col v-if="LINE.LTC || LINE.LINKED_BENEFIT" cols="12" lg="6">
                  <autocomplete-field v-model="inflation" :prepend-inner-icon="mdiCurrencyUsd" label="Inflation"
                    data-testid="inflation" :disabled="user.isGroupOne" :success="validInflationItem"
                    :items="INFLATION_ITEMS" @update:model-value="saveAttribute('inflation')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.inflation.controller.value" />
                    </template>
                  </autocomplete-field>
                </v-col>
                <v-col v-else-if="LINE.DISABILITY" cols="12" md="6">
                  <autocomplete-field v-model="inflation" :prepend-inner-icon="mdiCalendar" label="COLA"
                    data-testid="cola" :items="COLA_OPTIONS" :success="inflation !== undefined"
                    @update:model-value="saveAttribute('inflation')">
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.inflation.controller.value" />
                    </template>
                  </autocomplete-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import UnderwritingRationale from "@/components/shared/UnderwritingRationale.vue";
import DateInput from "@/components/shared/DateInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { STATES } from "@/data/states";
import {
  STATUSES,
  modeOptions,
  trackingTypes,
  RATINGS_BY_LINE,
  TAX_OPTIONS,
  ELIMINATION_PERIODS,
  INFORMAL_STATUSES,
  OCCUPATION_CLASSES,
  COLA_OPTIONS,
  DISABILITY_BENEFIT_PERIODS
} from "@/data/case-data";
import { BENEFIT_PERIODS, INFLATION_ITEMS } from "@/data/quote-request-items";
import { storeToRefs } from "pinia";

import { useUserStore } from "@/stores/user";
import { useCaseViewStore } from "@/stores/case-view";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed } from "vue";

import {
  mdiPound,
  mdiListStatus,
  mdiHomeGroup,
  mdiSitemap,
  mdiCurrencyUsd,
  mdiViewWeek,
  mdiStar,
  mdiBank,
  mdiBriefcase,
  mdiSignRealEstate,
  mdiTruckDelivery,
  mdiBarcode,
  mdiOpenInNew,
  mdiCalendar
} from "@mdi/js";

const user = useUserStore();
const caseView = useCaseViewStore();
const {
  insuredRole: _insuredRole,
  addresses,
  product,
  products,
  timeline,
  address,
  appliedMode,
  appliedPremium,
  benefitAmount,
  benefitMode,
  benefitPeriod,
  carrierEDelivery,
  cashReceived,
  chronicIllnessRider,
  conversion,
  eliminationPeriod,
  exchangeAmount,
  faceAmount,
  inflation,
  ltcRider,
  lumpSum,
  LINE,
  line,
  messageClient,
  mode,
  policyNumber,
  premium,
  saveAge,
  state,
  status,
  targetPremium,
  taxType,
  tia,
  trackingNumber,
  trackingNumberType
} = storeToRefs(caseView);

const savingBuffer = {
  address: useActiveSave(),
  appliedMode: useActiveSave(),
  appliedPremium: useActiveSave(),
  approvedDate: useActiveSave(),
  appSignDate: useActiveSave(),
  benefitAmount: useActiveSave(),
  benefitMode: useActiveSave(),
  benefitPeriod: useActiveSave(),
  cashReceived: useActiveSave(),
  carrierEDelivery: useActiveSave(),
  chronicIllnessRider: useActiveSave(),
  closedDate: useActiveSave(),
  conversionExpirationDate: useActiveSave(),
  conversion: useActiveSave(),
  deliveryRequirementsDueDate: useActiveSave(),
  eliminationPeriod: useActiveSave(),
  exchangeAmount: useActiveSave(),
  faceAmount: useActiveSave(),
  inflation: useActiveSave(),
  inforceDate: useActiveSave(),
  issueDate: useActiveSave(),
  ltcRider: useActiveSave(),
  lumpSum: useActiveSave(),
  messageClient: useActiveSave(),
  mode: useActiveSave(),
  occupationClass: useActiveSave(),
  policyDate: useActiveSave(),
  policyNumber: useActiveSave(),
  premium: useActiveSave(),
  product: useActiveSave(),
  ratingApplied: useActiveSave(),
  ratingApproved: useActiveSave(),
  saveAge: useActiveSave(),
  sentToAgentDate: useActiveSave(),
  state: useActiveSave(),
  status: useActiveSave(),
  submittedDate: useActiveSave(),
  targetPremium: useActiveSave(),
  taxType: useActiveSave(),
  tia: useActiveSave(),
  trackingNumber: useActiveSave(),
  trackingNumberType: useActiveSave(),
  underwritingRationale: useActiveSave()
};

const insuredRole = computed({
  get() {
    return _insuredRole.value;
  },
  set(v) {
    caseView.setInsuredRole(v);
  }
});

const BENEFIT_PERIOD_OPTIONS = LINE.value.DISABILITY
  ? DISABILITY_BENEFIT_PERIODS
  : BENEFIT_PERIODS;
const STATUS_OPTIONS = LINE.value.INFORMAL ? INFORMAL_STATUSES : STATUSES;
const validInflationItem = computed(() =>
  INFLATION_ITEMS.some(v => v.value === inflation.value)
);

const savableTimelineItem = (key, savingBufferKey) =>
  computed({
    get() {
      return timeline.value.find(i => i.key === key)?.date;
    },
    set(val) {
      const index = timeline.value.findIndex(i => i.key === key);
      if (index === -1) return;
      timeline.value[index].date = val || null;
      saveAttribute(savingBufferKey);
    }
  });

const appSignDate = savableTimelineItem("app_sign_date", "appSignDate");
const submittedDate = savableTimelineItem("submitted_date", "submittedDate");
const approvedDate = savableTimelineItem("approved_date", "approvedDate");
const issueDate = savableTimelineItem("issue_date", "issueDate");
const deliveryRequirementsDueDate = savableTimelineItem(
  "delivery_requirements_due_date",
  "deliveryRequirementsDueDate"
);
const sentToAgentDate = savableTimelineItem("sent_to_agent", "sentToAgentDate");
const policyDate = savableTimelineItem("policy_date", "policyDate");
const conversionExpirationDate = savableTimelineItem(
  "conversion_expiry",
  "conversionExpirationDate"
);
const inforceDate = savableTimelineItem("inforce_date", "inforceDate");
const closedDate = savableTimelineItem("closed_date", "closedDate");

const trackingNumberPrefix = computed(() => {
  return (
    {
      USPS: "https://tools.usps.com/go/TrackConfirmAction?tLabels=",
      FedEx: "https://www.fedex.com/fedextrack/?tracknumbers="
    }[trackingNumberType.value] || ""
  );
});
const trackingNumberLink = computed(() => {
  if (!trackingNumberPrefix.value || !trackingNumber.value) return "";
  return trackingNumberPrefix.value + trackingNumber.value;
});

const isTargetPremiumProduct = computed(() =>
  ["UL", "VUL", "Whole Life", "Whole Life - Non Participating", "IUL"].includes(
    product.value.planType
  )
);

const isTermProduct = computed(() =>
  ["TRM", "TRM - ROP", "TRM - Non Medical"].includes(product.value.planType)
);

function saveAttribute(attribute) {
  savingBuffer[attribute].controller.value.needsUpdate = true;
  updateAttributes();
}

let timer;
function updateAttributes() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    const attributesToUpdate = Object.keys(savingBuffer).filter(
      k => savingBuffer[k].controller.value.needsUpdate
    );

    const promise = caseView.saveAttributes(attributesToUpdate);

    attributesToUpdate.forEach(attr =>
      savingBuffer[attr].attachToPromise(promise)
    );
  }, 500);
}
</script>

<style lang="scss">
.has-prepend-text {
  .v-field.v-field--appended {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
