import { createApp, markRaw } from "vue";
import App from "@/App.vue";
import "@/stylesheet/main.scss";
import router from "@/router";
import vuetify from "@/plugins/vuetify";

import browserUpdate from "browser-update";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";
import { useInstanceStore } from "@/stores/instance";
import { syncWithLocalStoragePlugin } from "@/stores/sync-with-local-storage-plugin";
import { registerComponents } from "@/plugins/global-components";
import { attachAnalytics } from "@/plugins/analytics";

import * as Sentry from "@sentry/vue";

const app = createApp(App, { name: "BOSS" });

const mountEl = document.querySelector("#app");

const head = createHead();
app.use(head);
const pinia = createPinia();
app.use(pinia);

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: "https://51e55b8039fe1d15dc93bb3f3057da7b@o4506661584568320.ingest.us.sentry.io/4508321207681025",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],

  tracesSampleRate: 0.2,
  tracePropagationTargets: [
    "https://app.back9ins.com",
    "https://demo.back9ins.com",
    "https://staging.back9ins.com"
  ],
  replaysSessionSampleRate: 0.1
});

const nonce = mountEl?.dataset?.nonce;
app.use(router);
app.use(vuetify(nonce));
attachAnalytics({ nonce, analyticsId: import.meta.env.VITE_ANALYTICS_ID });
registerComponents(app);

browserUpdate({
  required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
  insecure: false,
  api: 2020.11
});

pinia.use(({ store }) => (store.router = markRaw(router)));
pinia.use(syncWithLocalStoragePlugin);

const instance = useInstanceStore(pinia);
instance.apiUrl = import.meta.env.VITE_API_URL;
instance.helpCenterUrl = import.meta.env.VITE_HELP_CENTER;

instance.setCsrfToken().then(() => app.mount("#app"));
