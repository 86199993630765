import { createRouter, createWebHistory } from "vue-router";

import SignUpOnboarding from "@/components/auth/SignUpOnboarding.vue";
import SignUp from "@/components/auth/SignUp.vue";
import AppHome from "@/components/home/Home.vue";
import QuotingView from "@/views/QuotingView.vue";
import QuoteAndApply from "@/views/QuoteAndApply.vue";
import TableView from "@/views/TableView.vue";
import CaseManagement from "@/views/CaseManagement.vue";
import CommissionsView from "@/views/CommissionsView.vue";
import FormsAndApplicationsView from "@/views/FormsAndApplicationsView.vue";
import ChangePassword from "@/components/auth/ChangePassword.vue";

import SettingsView from "@/components/settings/SettingsView.vue";
import AdvisorProfileView from "@/components/advisor-profile/AdvisorProfileView.vue";
import PersonalReportView from "@/components/reports/PersonalReportView.vue";
import ScorecardReport from "@/components/reports/ScorecardReport.vue";
import BackNineReportView from "@/components/reports/BackNineReportView.vue";
import CaseManagementReportView from "@/components/reports/CaseManagementReportView.vue";
import MarketingManagerReportView from "@/components/reports/MarketingManagerReportView.vue";
import CreateQuoteRequest from "@/components/quotes/quote-request/CreateQuoteRequest.vue";
import QuoteView from "@/components/quotes/QuoteView.vue";
import ImpairedRiskQuoteCreate from "@/components/impaired-risk-quote/ImpairedRiskQuoteCreate.vue";
import ImpairedRiskQuoteView from "@/components/impaired-risk-quote/ImpairedRiskQuoteView.vue";
import ApprovedDomainView from "@/components/approved-domain/ApprovedDomainView.vue";
import FormMappingTablesView from "@/components/form-mapping/FormMappingTablesView.vue";
import FormMappingView from "@/components/form-mapping/FormMappingView.vue";
import AppointmentCreate from "@/components/appointments/AppointmentCreate.vue";
import AppointmentView from "@/components/appointments/AppointmentView.vue";
import AdvisorCreate from "@/components/advisors/AdvisorCreate.vue";

import InboundCall from "@/components/InboundCall.vue";
import Reader from "@/components/help-center/HelpCenterReader.vue";
import HelpCenterCreator from "@/components/help-center/HelpCenterCreator.vue";
import HelpCenterSeries from "@/components/help-center/HelpCenterSeries.vue";
import HelpCenter from "@/components/help-center/HelpCenter.vue";
import LeadView from "@/components/leads/LeadView.vue";
import LeadCreate from "@/components/leads/LeadCreate.vue";
import MarketingView from "@/components/marketing/MarketingView.vue";
import ProductView from "@/components/products/ProductView.vue";
import ProductCreate from "@/components/products/ProductCreate.vue";
import ProductsTable from "@/components/products/ProductsTable.vue";
import TransactionCreate from "@/components/transactions/TransactionCreate.vue";
import EmployeeView from "@/components/employees/EmployeeView.vue";
import BackNineContactsTable from "@/components/contact/BackNineContactsTable.vue";
import PayPeriodView from "@/components/pay-period/PayPeriodView.vue";
import StatementView from "@/components/statements/StatementView.vue";
import StatementCreate from "@/components/statements/StatementCreate.vue";
import QueuedTransactionsTable from "@/components/queued-transactions/QueuedTransactionsTable.vue";
import StatementsTable from "@/components/statements/StatementsTable.vue";
import PaymentCreate from "@/components/payments/PaymentCreate.vue";
import BackNineCaseCommissionsTable from "@/components/commissions/BackNineCaseCommissionsTable.vue";
import PartyView from "@/components/parties/PartyView.vue";
import ContractPartyView from "@/components/contract-parties/contract-party-view/ContractPartyView.vue";
import ContractPartyCreate from "@/components/contract-parties/ContractPartyCreate.vue";
import CarrierView from "@/components/carriers/CarrierView.vue";
import PersonnelCreate from "@/components/personnel/PersonnelCreate.vue";
import PersonnelView from "@/components/personnel/PersonnelView.vue";
import CasesTable from "@/components/cases/CasesTable.vue";
import CaseCreate from "@/components/cases/case-create/CaseCreate.vue";
import CaseCreateInformal from "@/components/cases/case-create/CaseCreateInformal.vue";
import CaseSubmission from "@/components/cases/case-create/CaseSubmission.vue";
import CaseView from "@/components/cases/case-view/CaseView.vue";
import ElectronicApplicationView from "@/components/electronic-applications/ElectronicApplicationView.vue";
import ApplicationRequest from "@/components/forms/ApplicationRequest.vue";
import HolidaysView from "@/components/reports/HolidaysView.vue";
import { AVAILABLE_SERIES } from "@/composables/series-composable";
import SignIn from "@/components/auth/SignIn.vue";
import TwoFactorConfirmation from "@/components/auth/TwoFactorConfirmation.vue";
import TwoFactorEnrollmentRequired from "@/components/auth/TwoFactorEnrollmentRequired.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import CustomChatTemplateCreator from "@/components/custom-chat-templates/CustomChatTemplateCreator.vue";
import SupportTicketView from "@/components/support-tickets/SupportTicketView.vue";
import CarrierBridge from "@/components/CarrierBridge.vue";

import AuthLayout from "@/layouts/AuthLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import BareLayout from "@/layouts/BareLayout.vue";

import {
  authGuard,
  onboardingGuard,
  twoFactorConfirmationRequiredGuard,
  twoFactorEnrollmentRequiredGuard
} from "@/guards/auth.guard.js";
import {
  formMappingGuard,
  accountingGuard,
  scorecardGuard
} from "@/guards/permissions.guard.js";
import { isEmployeeGuard } from "@/guards/is-employee.guard.js";
import { groupTwoGuard } from "@/guards/group.guard.js";
import { commissionsEnabledGuard } from "@/guards/commissions-enabled.guard.js";
import { showBacknineGuard } from "@/guards/show-backnine.guard.js";
import { hasApprovedDomainGuard } from "@/guards/has-approved-domain.guard.js";
import { validResetPasswordTokenGuard } from "@/guards/valid-reset-password-token.guard.js";

import { getMe } from "@/api/boss.service";
import { handleRedirectAfterAuthentication } from "@/api/auth.service";
import { convertToEappFromToken } from "@/api/cases.service";

import { useSignupStore } from "@/stores/sign-up";
import { useUserStore } from "@/stores/user";
import { executeFirelightSSO } from "@/components/settings/security/sso.composable";
import ConversionLanding from "@/components/ConversionLanding.vue";
import { parseErrorMessage } from "@/util/helpers";
import { trackPageChange } from "@/plugins/analytics";

const routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout },
    beforeEnter: async (to, _, next) => {
      try {
        await getMe();
        await handleRedirectAfterAuthentication(router, next);
      } catch (e) {
        next();
      }
    }
  },

  {
    path: "/2fa",
    name: "TwoFactorConfirmation",
    component: TwoFactorConfirmation,
    beforeEnter: twoFactorConfirmationRequiredGuard,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout }
  },
  {
    path: "/2fa-required",
    name: "TwoFactorEnrollmentRequired",
    component: TwoFactorEnrollmentRequired,
    beforeEnter: twoFactorEnrollmentRequiredGuard,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout }
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout },
    beforeEnter: validResetPasswordTokenGuard,
    props: route => ({
      resetPasswordToken: route.query.reset_password_token
    })
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: ChangePassword,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout },
    beforeEnter: validResetPasswordTokenGuard,
    props: route => ({
      resetPasswordToken: route.query.reset_password_token,
      promptAction: "Set"
    })
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    beforeEnter: async (to, _, next) => {
      const store = useSignupStore();
      store.$reset();
      store.setData(to);
      next();
    },
    meta: { bypassStdAuthGuard: true, layout: AuthLayout }
  },
  {
    path: "/onboarding-sign-up",
    name: "OnboardingSignup",
    component: SignUpOnboarding,
    beforeEnter: onboardingGuard,
    meta: { bypassStdAuthGuard: true, layout: AuthLayout }
  },
  {
    path: "/join/:referralCode",
    name: "ReferralSignUp",
    component: SignUp,
    beforeEnter: async (to, _, next) => {
      if (!to.params.referralCode) return next({ name: "SignUp" });
      const store = useSignupStore();
      store.$reset();
      store.setData(to);
      await store.fetchCode();
      next();
    },
    meta: { bypassStdAuthGuard: true, layout: AuthLayout }
  },
  {
    path: "/advisor-verification/:uuid",
    meta: { bypassStdAuthGuard: true },
    component: AppHome,
    beforeEnter: async (to, _, next) => {
      if (!to.params.uuid) next({ name: "SignIn" });
      const attestationRoute = `/auth/attestation?eapp_id=${to.params.uuid}`;
      try {
        await getMe();
        window.location.replace(attestationRoute);
        await new Promise();
      } catch (e) {
        //do nothing
        next({ name: "SignIn", query: { redirect: attestationRoute } });
      }
    }
  },

  {
    path: "/carrier-bridge/firelight",
    meta: { layout: AuthLayout },
    component: CarrierBridge,
    props: route => ({ eappUuid: route.query.eapp_uuid }),
    beforeEnter: [
      authGuard,
      async to => {
        const eappUuid = to.query.eapp_uuid;
        await executeFirelightSSO(eappUuid);
      }
    ]
  },

  {
    path: "/conversions/:token",
    meta: { bypassStdAuthGuard: true, layout: AuthLayout },
    component: ConversionLanding,
    name: "CarrierBridge",
    props: to => ({ token: to.params.token, error: to.query.error }),
    beforeEnter: async (to, from, next) => {
      if (!to.params.token) return next({ name: "Home" });
      if (to.query.error) return next();
      try {
        const res = await convertToEappFromToken(to.params.token);
        location.replace(res.url);
      } catch (e) {
        return next({
          name: "CarrierBridge",
          params: { token: to.params.token },
          query: { error: parseErrorMessage(e) }
        });
      }
    }
  },

  // applications
  {
    path: "/applications",
    name: "FormsAndApplicationsView",
    component: FormsAndApplicationsView,
    meta: { layout: AppLayout }
  },
  {
    path: "/applications/request",
    name: "ApplicationRequest",
    component: ApplicationRequest,
    beforeEnter: showBacknineGuard,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Application",
      breadcrumbs: [
        {
          text: "Forms & Applications",
          to: { name: "FormsAndApplicationsView" }
        }
      ]
    }
  },

  // reports
  {
    path: "/reports/personal",
    name: "PersonalReportView",
    component: PersonalReportView,
    meta: { layout: AppLayout }
  },
  {
    path: "/reports/scorecard",
    name: "ScorecardReport",
    component: ScorecardReport,
    beforeEnter: scorecardGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/reports/holidays",
    name: "HolidaysView",
    component: HolidaysView,
    beforeEnter: groupTwoGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/reports/backnine",
    name: "BackNineReportView",
    component: BackNineReportView,
    beforeEnter: groupTwoGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/reports/case-management",
    name: "CaseManagementReportView",
    component: CaseManagementReportView,
    beforeEnter: groupTwoGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/reports/marketing-manager",
    name: "MarketingManagerReportView",
    component: MarketingManagerReportView,
    beforeEnter: groupTwoGuard,
    meta: { layout: AppLayout }
  },

  // Quotes
  {
    path: "/quotes",
    name: "Quotes",
    component: QuotingView,
    beforeEnter: showBacknineGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/quotes/create",
    name: "CreateQuote",
    props: route => ({ ...route.params }),
    component: CreateQuoteRequest,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Quote Request",
      breadcrumbs: [
        {
          text: "Quotes",
          to: { name: "Quotes" }
        }
      ]
    },
    beforeEnter: showBacknineGuard
  },
  {
    path: "/quotes/:id",
    name: "QuoteView",
    component: QuoteView,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Quotes",
          to: { name: "Quotes" }
        }
      ]
    },
    props: route => ({
      id: +route.params.id,
      page: route.query.page,
      highlightTodo: route.query["highlight-todo"]
        ? +route.query["highlight-todo"]
        : null
    }),
    beforeEnter: showBacknineGuard
  },

  // impaired risk quotes
  {
    path: "/impaired-risk-quotes/create",
    beforeEnter: showBacknineGuard,
    name: "ImpairedRiskQuoteCreate",
    component: ImpairedRiskQuoteCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Impaired Risk Quote",
      breadcrumbs: user => {
        let to = { name: "Quotes" };
        if (user.loginable.is_case_manager) {
          to = { name: "Tables", query: { page: "underwriting" } };
        }
        return [{ text: "Quoting", to }];
      }
    }
  },
  {
    path: "/impaired-risk-quotes/:id",
    name: "ImpairedRiskQuoteView",
    beforeEnter: showBacknineGuard,
    component: ImpairedRiskQuoteView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;

      return {
        id: +route.params.id,
        page: route.query?.page,
        offer: route.query?.offer,
        highlightTodo,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: user => {
        let to = { name: "Quotes" };
        if (user.loginable.is_case_manager) {
          to = { name: "Tables", query: { page: "underwriting" } };
        }
        return [{ text: "Quoting", to }];
      }
    }
  },

  // quote-and-apply
  {
    path: "/quote-and-apply",
    name: "QuoteAndApply",
    component: QuoteAndApply,
    props: route => ({ view: route.query.view }),
    meta: { layout: AppLayout }
  },
  {
    path: "/quote-and-apply/:id",
    name: "ApprovedDomains",
    props: route => ({
      id: route.params.id,
      page: route.query.page,
      scrollTo: route.query["scroll-to"]
    }),
    component: ApprovedDomainView,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Quote & Apply",
          to: { name: "QuoteAndApply" }
        }
      ]
    }
  },

  // forms
  {
    path: "/forms",
    name: "FormMappingForms",
    component: FormMappingTablesView,
    beforeEnter: formMappingGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/forms/:id",
    name: "FormMappingEditor",
    component: FormMappingView,
    props: route => {
      let aqlId = null;
      if (route.query["aql-id"]) aqlId = +route.query["aql-id"];

      let copiedForm = {};
      if (route.query["copied-form"]) {
        try {
          copiedForm = JSON.parse(route.query["copied-form"]);
        } catch (e) {
          // do nothing
          copiedForm = {};
        }
      }

      return {
        id: +route.params.id,
        aqlId,
        copiedForm
      };
    },
    meta: {
      layout: AppLayout,
      miniSidebar: true,
      useStateVal: true,
      useBreadcrumbSlot: true,
      breadcrumbs: [
        {
          text: "Forms",
          to: { name: "FormMappingForms" }
        }
      ]
    },
    beforeEnter: formMappingGuard
  },

  // appointments
  {
    path: "/appointments/create",
    name: "AppointmentCreate",
    component: AppointmentCreate,
    beforeEnter: groupTwoGuard,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Appointment",
      breadcrumbs: [
        {
          text: "Appointments",
          to: { name: "Tables", query: { page: "appointments" } }
        }
      ]
    }
  },
  {
    path: "/appointments/:id",
    name: "AppointmentView",
    component: AppointmentView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;

      return {
        id: route.params.id,
        page: route.query.page,
        highlightTodo,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Appointments",
          to: { name: "Tables", query: { page: "appointments" } }
        }
      ]
    }
  },

  // advisors
  {
    path: "/advisors/create",
    name: "AdvisorCreate",
    component: AdvisorCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Advisor",
      breadcrumbs: [
        {
          text: "Advisors",
          to: { name: "Tables", query: { page: "advisors" } }
        }
      ]
    }
  },

  // profile
  {
    path: "/profile",
    name: "LoggedInUserProfile",
    component: AdvisorProfileView,
    props: route => {
      const user = useUserStore();
      return {
        id: user.loginable.id,
        type: user.loginable.type,
        page: route.query?.page,
        resource: route.query?.resource
      };
    },
    meta: { layout: AppLayout }
  },
  {
    path: "/agents/:id",
    name: "AgentView",
    component: AdvisorProfileView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;
      return {
        type: "Agent",
        id: +route.params.id,
        page: route.query?.page,
        resource: route.query?.resource,
        highlightTodo,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Advisors",
          to: { name: "Tables", query: { page: "advisors" } }
        }
      ]
    }
  },
  {
    path: "/agencies/:id",
    name: "AgencyView",
    component: AdvisorProfileView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;

      return {
        type: "Agency",
        id: +route.params.id,
        page: route.query?.page,
        resource: route.query?.resource,
        highlightTodo,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Advisors",
          to: { name: "Tables", query: { page: "advisors" } }
        }
      ]
    }
  },
  // Agents/Agencies Deprecated
  {
    path: "/advisors/:type/:id",
    name: "AdvisorView",
    redirect: to => {
      if (to.params.type.includes("agenc")) {
        return {
          name: "AgencyView",
          params: { id: +to.params.id },
          query: to.query
        };
      }
      return {
        name: "AgentView",
        params: { id: +to.params.id },
        query: to.query
      };
    }
  },

  // carriers
  {
    path: "/carriers/:id",
    beforeEnter: showBacknineGuard,
    name: "CarrierView",
    component: CarrierView,
    props: route => ({
      id: +route.params.id,
      page: route.query?.page
    }),
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Carriers",
          to: { name: "Tables", query: { page: "carriers" } }
        }
      ]
    }
  },

  // personnel
  {
    path: "/personnel/create",
    name: "PersonnelCreate",
    component: PersonnelCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Personnel",
      breadcrumbs: [
        {
          text: "Carrier Personnel",
          to: { name: "Tables", query: { page: "carrier-personnel" } }
        }
      ]
    },
    beforeEnter: groupTwoGuard
  },
  {
    path: "/personnel/:id",
    name: "PersonnelView",
    component: PersonnelView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;
      return {
        id: +route.params.id,
        page: route.query?.page,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Carrier Personnel",
          to: { name: "Tables", query: { page: "carrier-personnel" } }
        }
      ]
    }
  },

  // cases
  {
    path: "/cases",
    name: "CasesTable",
    component: CasesTable,
    meta: { layout: AppLayout }
  },
  {
    path: "/cases/create",
    name: "CaseCreate",
    component: CaseCreate,
    beforeEnter: groupTwoGuard,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Case",
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    }
  },
  {
    path: "/cases/informal/create",
    name: "CaseCreateInformal",
    component: CaseCreateInformal,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Informal Case",
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    }
  },
  {
    path: "/cases/submit",
    name: "CaseSubmission",
    component: CaseSubmission,
    meta: {
      layout: AppLayout,
      breadcrumb: "Case Submission",
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    }
  },
  {
    path: "/cases/:id",
    name: "CaseView",
    component: CaseView,
    meta: {
      layout: AppLayout,
      hideNeedHelpSupportTicket: true,
      useStateVal: true,
      breadcrumbs: [{ text: "Cases", to: { name: "CasesTable" } }]
    },
    props: route => {
      let task;
      let taskId = route.query?.task;
      let taskType = route.query?.type;
      if (taskType && taskId) task = `${taskType}-${taskId}`;
      else if (taskId && taskId.includes("-")) task = taskId;

      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;
      return {
        id: +route.params.id,
        page: route.query?.page,
        task,
        highlightTodo,
        highlightNote
      };
    }
  },

  // electronic-applications
  {
    path: "/electronic-applications/:id",
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;

      return {
        id: +route.params.id,
        page: route.query?.page,
        fromCase: route.query?.["from-case"],
        highlightTodo,
        highlightNote
      };
    },
    name: "ElectronicApplicationView",
    component: ElectronicApplicationView,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      hideNeedHelpSupportTicket: true,
      breadcrumbs: [
        {
          text: "Quote & Apply",
          to: { name: "QuoteAndApply", query: { view: "list" } }
        }
      ]
    }
  },

  // contract parties, coloquially known as clients
  {
    path: "/clients/create",
    name: "ContractPartyCreate",
    beforeEnter: showBacknineGuard,
    component: ContractPartyCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Client",
      breadcrumbs: [
        {
          text: "Clients",
          to: { name: "Tables", query: { page: "clients" } }
        }
      ]
    }
  },
  {
    path: "/clients/:id",
    name: "ContractPartyView",
    component: ContractPartyView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;
      return {
        id: +route.params.id,
        page: route.query.page,
        highlightTodo,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Clients",
          to: { name: "Tables", query: { page: "clients" } }
        }
      ]
    }
  },

  // case-management
  {
    path: "/case-management",
    name: "CaseManagement",
    component: CaseManagement,
    meta: { layout: AppLayout }
  },

  // party view
  {
    path: "/individuals/:id",
    name: "IndividualView",
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      return {
        id: +route.params.id,
        type: "Individual",
        highlightNote
      };
    },
    component: PartyView,
    meta: { useStateVal: true, layout: AppLayout }
  },
  {
    path: "/entities/:id",
    name: "EntityView",
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      return { id: +route.params.id, type: "Entity", highlightNote };
    },
    component: PartyView,
    meta: { useStateVal: true, layout: AppLayout }
  },

  // commissions
  {
    path: "/commissions",
    name: "Commissions",
    props: route => ({ page: route.query.page }),
    component: CommissionsView,
    beforeEnter: [commissionsEnabledGuard, showBacknineGuard],
    meta: { layout: AppLayout }
  },
  {
    path: "/commissions/payments/create",
    name: "PaymentCreate",
    component: PaymentCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Payment",
      breadcrumbs: [
        {
          text: "Commissions",
          to: { name: "Commissions", query: { page: "payments" } }
        }
      ]
    },
    beforeEnter: accountingGuard
  },
  {
    path: "/commissions/pay-periods/:id",
    name: "PayPeriodView",
    component: PayPeriodView,
    props: route => ({ id: +route.params.id }),
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Commissions",
          to: { name: "Commissions", query: { page: "pay-periods" } }
        }
      ]
    },
    beforeEnter: [commissionsEnabledGuard, showBacknineGuard]
  },

  // b9
  {
    path: "/b9/commissions",
    beforeEnter: accountingGuard,
    name: "BackNineCommissionsDashboard",
    component: BackNineCaseCommissionsTable,
    meta: { layout: AppLayout }
  },
  {
    path: "/b9/statements",
    name: "BackNineCommissionsStatements",
    component: StatementsTable,
    beforeEnter: accountingGuard,
    meta: { layout: AppLayout }
  },
  {
    path: "/b9/statements/create",
    name: "StatementCreate",
    component: StatementCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create BackNine Statement",
      breadcrumbs: [
        {
          text: "BackNine Statements",
          to: { name: "BackNineCommissionsStatements" }
        }
      ]
    },
    beforeEnter: accountingGuard
  },
  {
    path: "/b9/statements/pending-transactions",
    name: "BackNinePendingTransactions",
    component: QueuedTransactionsTable,
    meta: {
      layout: AppLayout,
      breadcrumb: "Pending Transactions",
      breadcrumbs: [
        {
          text: "BackNine Statements",
          to: { name: "BackNineCommissionsStatements" }
        }
      ]
    },
    beforeEnter: accountingGuard
  },
  {
    path: "/b9/statements/:id",
    name: "StatementView",
    props: route => ({
      id: route.params.id,
      page: route.query.page
    }),
    component: StatementView,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "BackNine Statements",
          to: { name: "BackNineCommissionsStatements" }
        }
      ]
    },
    beforeEnter: accountingGuard
  },

  // contact
  {
    path: "/contact",
    name: "Contact",
    component: BackNineContactsTable,
    meta: { layout: AppLayout }
  },

  // employees
  {
    path: "/employees/:id",
    props: route => ({ id: +route.params.id, page: route.query?.page }),
    name: "EmployeeView",
    component: EmployeeView,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Employees",
          to: { name: "Tables", query: { page: "employees" } }
        }
      ]
    }
  },

  // transactions
  {
    path: "/transactions/create",
    name: "TransactionCreate",
    component: TransactionCreate,
    beforeEnter: accountingGuard,
    meta: { layout: AppLayout }
  },

  // products
  {
    path: "/products",
    name: "Products",
    component: ProductsTable,
    beforeEnter: [commissionsEnabledGuard, showBacknineGuard],
    meta: { layout: AppLayout }
  },
  {
    path: "/products/create",
    name: "ProductCreate",
    component: ProductCreate,
    beforeEnter: accountingGuard,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Product",
      breadcrumbs: [{ text: "Products", to: { name: "Products" } }]
    }
  },
  {
    path: "/products/:id",
    name: "ProductView",
    props: route => ({
      id: +route.params.id,
      page: route.query?.page
    }),
    component: ProductView,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [{ text: "Products", to: { name: "Products" } }]
    }
  },

  // marketing
  {
    path: "/marketing",
    component: MarketingView,
    name: "MarketingEmails",
    beforeEnter: hasApprovedDomainGuard,
    meta: { layout: AppLayout }
  },

  //leads
  {
    beforeEnter: groupTwoGuard,
    path: "/leads/create",
    name: "LeadCreate",
    component: LeadCreate,
    meta: {
      layout: AppLayout,
      breadcrumb: "Create Lead",
      breadcrumbs: [
        {
          text: "Leads",
          to: { name: "Tables", query: { page: "leads" } }
        }
      ]
    }
  },
  {
    beforeEnter: groupTwoGuard,
    path: "/leads/:id",
    name: "LeadView",
    component: LeadView,
    props: route => {
      let highlightNote = route.query["highlight-note"];
      if (highlightNote) highlightNote = +highlightNote;

      let highlightTodo = route.query["highlight-todo"];
      if (highlightTodo) highlightTodo = +highlightTodo;
      return {
        id: +route.params.id,
        page: route.query?.page,
        highlightTodo,
        highlightNote
      };
    },
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [
        {
          text: "Leads",
          to: { name: "Tables", query: { page: "leads" } }
        }
      ]
    }
  },

  // Tables
  {
    path: "/tables",
    name: "Tables",
    component: TableView,
    props: route => ({ page: route.query?.page }),
    meta: { layout: AppLayout }
  },

  {
    path: "/support-tickets/:id",
    name: "SupportTicketView",
    component: SupportTicketView,
    props: route => ({ id: route.params.id }),
    meta: {
      layout: AppLayout,
      hideNeedHelpSupportTicket: true,
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    }
  },
  // help-center
  {
    path: "/help-center",
    name: "HelpCenter",
    component: HelpCenter,
    meta: { layout: AppLayout }
  },
  {
    path: "/help-center/series/:id",
    name: "HelpCenterSeries",
    component: HelpCenterSeries,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    },
    props: route => ({ id: route.params.id }),
    beforeEnter: (to, from, next) => {
      const available = Object.values(AVAILABLE_SERIES);
      if (available.includes(to.params.id)) return next();
      return next({ name: "Home" });
    }
  },
  {
    path: "/help-center/articles/:id",
    name: "HelpCenterArticleViewer",
    component: Reader,
    props: route => ({ id: route.params.id }),
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    }
  },
  {
    path: "/help-center/articles/:id/edit",
    name: "HelpCenterArticleEditor",
    props: route => ({ id: route.params.id }),
    component: HelpCenterCreator,
    meta: {
      layout: AppLayout,
      useStateVal: true,
      breadcrumbs: [{ text: "Help Center", to: { name: "HelpCenter" } }]
    },
    beforeEnter: groupTwoGuard
  },

  // Inbound Call
  {
    path: "/inbound-call/:phone",
    name: "InboundCallSearch",
    component: InboundCall,
    props: route => ({ phone: route.params.phone }),
    beforeEnter: (to, from, next) => {
      const rawPhone = to.params.phone;
      if (!to.params.phone) return next({ name: "Home" });

      let phone = rawPhone.replace(/^\D+/g, "");
      if (phone.startsWith("1")) phone = phone.substr(1);

      if (phone.length < 10) return next({ name: "Home" });

      to.params.phone = phone;
      return next();
    },
    meta: { layout: AppLayout }
  },

  {
    beforeEnter: isEmployeeGuard,
    path: "/custom-chat-templates/create",
    name: "CustomChatTemplateCreator",
    component: CustomChatTemplateCreator,
    meta: {
      layout: AppLayout,
      useBreadcrumbSlot: true,
      breadcrumb: "Create Custom Chat Template",
      breadcrumbs: [
        {
          text: "Custom Chat Templates",
          to: { name: "Tables", query: { page: "custom-chat-templates" } }
        }
      ]
    }
  },
  {
    beforeEnter: isEmployeeGuard,
    path: "/custom-chat-templates/:id",
    name: "CustomChatTemplateEditor",
    component: CustomChatTemplateCreator,
    props: route => ({ id: route.params.id }),
    meta: {
      layout: AppLayout,
      useStateVal: true,
      useBreadcrumbSlot: true,
      breadcrumb: "Custom Chat Template Viewer",
      breadcrumbs: [
        {
          text: "Custom Chat Templates",
          to: { name: "Tables", query: { page: "custom-chat-templates" } }
        }
      ]
    }
  },

  // Settings
  {
    path: "/settings",
    name: "LoggedInUserSettings",
    component: SettingsView,
    props: route => {
      const user = useUserStore();
      return {
        id: user.loginable.id,
        page: route.query?.page,
        type: user.loginable.type,
        action: route.query?.action
      };
    },
    meta: { layout: BareLayout }
  },
  {
    path: "/agencies/:id/settings",
    name: "AgencyEdit",
    component: SettingsView,
    props: route => ({
      id: +route.params.id,
      page: route.query?.page,
      type: "Agency",
      action: route.query?.action
    }),
    meta: { layout: BareLayout }
  },
  {
    path: "/agents/:id/settings",
    name: "AgentEdit",
    component: SettingsView,
    props: route => ({
      id: +route.params.id,
      page: route.query?.page,
      type: "Agent",
      action: route.query?.action
    }),
    meta: { layout: BareLayout }
  },
  // Settings - Deprecated
  {
    path: "/advisors/:type/:id/settings",
    name: "AdvisorEdit",
    redirect: to => {
      if (to.params.type.includes("agenc")) {
        return {
          name: "AgencyEdit",
          params: {
            id: to.params.id,
            page: to.query?.page,
            action: to.query?.action
          }
        };
      }
      return {
        name: "AgentEdit",
        params: {
          id: to.params.id,
          page: to.query?.page,
          action: to.query?.action
        }
      };
    }
  },

  {
    path: "",
    name: "Home",
    component: AppHome,
    props: to => ({ action: to.query?.action }),
    meta: { layout: AppLayout }
  },

  { path: "/:pathMatch(.*)*", redirect: "/" }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes
});

router.beforeEach((to, from, next) => {
  const pageComparison = ({ name, params }) => JSON.stringify({ name, params });
  if (pageComparison(to) === pageComparison(from)) return next();
  if (to.meta.bypassStdAuthGuard) return next();
  return authGuard(to, from, next);
});

router.afterEach(to => {
  trackPageChange({ page_title: to.name, page_location: to.fullPath });
});

export default router;
