let hasLoaded;
export function attachAnalytics({ nonce, analyticsId }) {
  hasLoaded = true;

  const script = document.createElement("script");
  script.defer = true;
  script.id = "gtag";
  script.type = "text/javascript";
  script.nonce = nonce;

  const url = new URL("https://www.googletagmanager.com/gtag/js");
  url.searchParams.append("id", analyticsId);

  script.src = url.toString();

  script.onload = () => {
    window.addEventListener("click", trackUserHasInteracted, { once: true });

    window.dataLayer = window.dataLayer || [];

    gtag("js", new Date());

    // Send page view must be false due to this being an SPA
    gtag("config", analyticsId, {
      send_page_view: false,
      cookie_flags: "samesite=none;secure",
      groups: "analytics"
    });
  };

  document.head.appendChild(script);
}

function gtag() {
  try {
    window.dataLayer.push(arguments);
  } catch (e) {
    // do nothing
  }
}

export function trackPageChange({ page_title, page_location }) {
  if (!hasLoaded) return;
  gtag("event", "page_view", {
    page_title,
    page_location,
    send_to: "analytics"
  });
}

export function trackUserHasInteracted() {
  if (!hasLoaded) return;
  gtag("event", "user_has_interacted", { send_to: "analytics" });
}
